import axios from 'axios';
import { Message } from 'element-ui';
import store from "@/store";
// import store from "@/store";
// let baseURL = 'http://ce.chuoooo.com/api';

export const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/', // 这里尽量不要加多余的前缀，后端新建文件之后可能会造成 /api 变成/fan文件变化形成新的接口
  // 超时
  timeout: 20000
})

service.interceptors.request.use(
  config => {
    const token = JSON.parse(window.localStorage.getItem('token'))
    if (token) config.headers['Authorization'] = token

    return config
  },
  err => {
      Message({
          message: "出问题啦，请刷新一下",
          type: 'error'
      })
    return Promise.reject(err)
  }
)

service.interceptors.response.use(
  data => {
    return data.data
  },
  err => {
    Message({
      message: err.message,
      type: 'error'
    })
    return Promise.reject(err)
  }
)

export const otherService = axios.create({
    baseURL: '/other',
    timeout: 20000
})

otherService.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem('token')
        if (token) config.headers['Authorization'] = token
        if (token) config.headers['token'] = token
        return config
    },
    err => {
        Message({
            message: "出问题啦，请刷新一下",
            type: 'error'
        })
        return Promise.reject(err)
    }
)

otherService.interceptors.response.use(
    data => {
        return data.data
    },
    err => {
        Message({
            message: err.message,
            type: 'error'
        })
        return Promise.reject(err)
    }
)


export const ButLoadingHttps = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '',
    // 超时
    timeout: 20000
})

ButLoadingHttps.interceptors.request.use(
    config => {
        store.commit('SET_BUTLAODING',true)
        const token = window.localStorage.getItem('token')
        if (token) config.headers['Authorization'] = token
        if (token) config.headers['token'] = token

        return config
    },
    err => {
        Message({
            message: "出问题啦，请刷新一下",
            type: 'error'
        })
        return Promise.reject(err)
    }
)

ButLoadingHttps.interceptors.response.use(
    data => {
        store.commit('SET_BUTLAODING',false)
        return data.data
    },
    err => {
        Message({
            message: err.message,
            type: 'error'
        })
        return Promise.reject(err)
    }
)

export const pageLoadingHttps= axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '',
    // 超时
    timeout: 20000
})

pageLoadingHttps.interceptors.request.use(
    config => {
        store.commit('SET_PAGLOADING',true)
        const token = window.localStorage.getItem('token')
        if (token) config.headers['Authorization'] = token
        if (token) config.headers['token'] = token
        return config
    },
    err => {
        Message({
            message: "出问题啦，请刷新一下",
            type: 'error'
        })
        return Promise.reject(err)
    }
)

pageLoadingHttps.interceptors.response.use(
    data => {
        store.commit('SET_PAGLOADING',false)
        return data.data
    },
    err => {
        Message({
            message: err.message,
            type: 'error'
        })
        return Promise.reject(err)
    }
)

export const testReq = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '',
    // 超时
    timeout: 20000
})

testReq.interceptors.request.use(
    config => {
      const token = JSON.parse(window.localStorage.getItem('token'))
      if (token) config.headers['Authorization'] = token
  
      return config
    },
    err => {
        Message({
            message: "出问题啦，请刷新一下",
            type: 'error'
        })
      return Promise.reject(err)
    }
  )