<template>
	<div class="IPlicensing">
		<el-form :model="form" class="form" label-width="100px" ref="form">
			<el-form-item label="授权时限">
				<el-radio-group v-model="form.radio">
					<el-radio
						v-for="item in optionsTime"
						:key="item.value"
						:label="item.label" />
					<el-radio :label="1">
						<span v-if="form.radio == '1'" class="time">
							<el-date-picker
								v-model="pickerValue"
								type="datetimerange"
								range-separator="至"
								start-placeholder="开始日期"
								ref="pickerRef"
								format="yyyy-MM-dd"
								end-placeholder="结束日期">
							</el-date-picker>
							<span class="diy-input">
								<input type="text" v-model="countDay" class="day" /><span
									class="content"
									>天</span
								>
							</span>
						</span>
						<span v-else>自定义</span>
					</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="授权区域">
				<div class="area">
					<el-radio-group v-model="form.radioArea">
						<el-radio label="全球" />
						<el-radio label="部分区域" />
					</el-radio-group>
					<el-checkbox-group
						class="area-checkbox"
						v-if="form.radioArea === '部分区域'"
						v-model="form.checkbox">
						<el-checkbox
							v-for="item in optionsArea"
							:key="item.name"
							:label="item.name"
							:value="item.check" />
					</el-checkbox-group>
				</div>
			</el-form-item>
			<el-form-item label="授权权限">
				<el-radio-group v-model="form.limitsOfAuthority">
					<el-radio
						v-for="item in optionsAuthority"
						:key="item.value"
						:label="item.label" />
				</el-radio-group>
			</el-form-item>

			<el-form-item label="代理授权">
				<el-radio-group v-model="form.agent">
					<el-radio
						v-for="item in optiosn"
						:key="item.value"
						:label="item.label" />
				</el-radio-group>
			</el-form-item>

			<el-form-item label="二创商用">
				<el-radio-group v-model="form.SApproved">
					<el-radio
						v-for="item in optiosn"
						:key="item.value"
						:label="item.label" />
				</el-radio-group>
			</el-form-item>

			<el-form-item label="授权类型">
				<el-select v-model="form.label" placeholder="请选择">
					<el-option
						v-for="item in optionsLabel"
						:key="item.value"
						:label="item.label"
						:value="item.value"
						:disabled="item.disabled">
					</el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="授权明细">
				<div class="textarea">
					<el-input
						type="textarea"
						placeholder="请输入具体可以使用的内容..."
						v-model="form.textarea"
						resize="none"
						maxlength="100"
						rows="3"
						show-word-limit />
					<span
						>如
						<el-popover
							placement="top-start"
							width="200"
							trigger="hover"
							v-for="item in staticImgs"
							:key="item.url">
							<slot>
								<img style="width: 100%" :src="item.url" :alt="item.alt" />
							</slot>
							<span slot="reference" class="special-text"
								>、{{ item.alt }}</span
							>
						</el-popover>
						等</span
					>
				</div>
			</el-form-item>

			<el-form-item label="IP授权信息">
				<div class="info">
					<el-input
						type="text"
						style="width: 30%"
						placeholder="请输入IP名称"
						v-model="form.title"
						maxlength="12"
						show-word-limit />
					<el-input
						class="price"
						style="width: 30%"
						type="number"
						placeholder="请输入授权价格"
						v-model="form.price"
						suffix-icon="el-icon-date" />
				</div>
			</el-form-item>

			<el-form-item label="上传例图">
				<div>
					<div class="upload-box">*上传的例图在3M以内,最多可上传10张;</div>
					<el-upload
						class="upload-iplicensing"
						action="/api/base/upload"
						list-type="picture-card"
						:limit="10"
						:file-list="fileList"
						:before-upload="beforeAvatarUpload"
						:on-success="handleAvatarSuccess"
						:on-remove="handleRemove"
						:on-exceed="handleExceed">
						<div class="upload-text-ip">
							<i class="iconfont icon-shangchuanneirong"></i>
							<span class="text">上传IP图片或样品图</span>
						</div>
					</el-upload>
				</div>
			</el-form-item>

			<el-form-item label="IP介绍">
				<el-input
					type="textarea"
					placeholder="请输入具体可以使用的内容..."
					v-model="form.desc"
					resize="none"
					maxlength="2000"
					rows="3"
					show-word-limit />
			</el-form-item>

			<el-form-item label="IP下载内容">
				<el-radio-group v-model="radioContent">
					<el-radio label="1">压缩包</el-radio>
					<el-radio label="2">百度云链接</el-radio>
				</el-radio-group>
				<el-upload
					v-if="radioContent === '1'"
					class="uploader"
					action="/api/space/uploadjustzipfile"
					:show-file-list="false"
					:on-success="handleGoodsImagesUploadSuccessDoc"
					:before-upload="handlebeforeUploadDoc"
					:on-exceed="handleUploadExceedDoc"
					:on-remove="handleRemoveDoc"
					drag>
					<div class="notContent">
						<i
							:class="[
								'iconfont',
								'icon-shangchuanyasuobao',
								'avatar-uploader-icon',
								!!form.fileImg ? 'active' : '',
							]" />
						<span v-if="form.fileImg">{{ zipFile.name }}</span>
						<span v-else>仅支持ZIP格式</span>
					</div>
				</el-upload>
				<div v-else class="url-pwd">
					<el-input placeholder="输入百度网盘地址" v-model="form.url" />
					<el-input placeholder="输入提取码" v-model="form.pwd" class="pwd" />
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { toDateString } from "@/utils";
export default {
	props: {
		type: {
			type: Number,
			default: 1,
		},
		radio: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isVisible: true,
			baseUrl: this.$store.state.baseUrl,
			radioContent: "1",
			optionsTime: [
				{ value: 90, label: "三个月" },
				{ value: 180, label: "六个月" },
				{ value: 270, label: "九个月" },
				{ value: 4, label: "一年" },
			],

			optionsArea: [
				{
					name: "中国",
					check: false,
				},
				{
					name: "欧洲",
					check: false,
				},
				{
					name: "非洲",
					check: false,
				},
				{
					name: "中东",
					check: false,
				},
				{
					name: "北美",
					check: false,
				},
				{
					name: "亚太",
					check: false,
				},
				{
					name: "拉丁美洲",
					check: false,
				},
				{
					name: "加勒比",
					check: false,
				},
			],
			optionsAuthority: [
				{ value: 0, label: "独占" },
				{ value: 1, label: "非独占" },
			],
			optiosn: [
				{
					value: 0,
					label: "允许",
				},
				{
					value: 1,
					label: "不允许",
				},
			],
			optionsLabel: [
				{
					label: "衍生品授权",
					value: "衍生品授权",
				},
				{
					label: "外包装授权",
					value: "外包装授权",
				},
				{
					label: "赠品授权",
					value: "赠品授权",
				},
				{
					label: "营销授权",
					value: "营销授权",
				},
				{
					label: "知识产权改编",
					value: "知识产权改编",
				},
			],
			fileList: [],
			limitDoc: 1,

			zipFile: "",

			pickerValue: "",
			form: {
				radio: "三个月",
				radioArea: "全球",
				checkbox: [],
				limitsOfAuthority: "独占",
				agent: "允许",
				SApproved: "允许",
				textarea: "",
				title: "",
				price: "",
				goodPic: [],
				desc: "",
				fileImg: "",
				url: "",
				pwd: "",
				label: "",
			},
		};
	},

	computed: {
		staticImgs() {
			return [
				{
					url: require("@/assets/img/iplicensing/paper.png"),
					alt: "剪纸",
				},
				{
					url: require("@/assets/img/iplicensing/cup.png"),
					alt: "水杯",
				},
			];
		},

		countDay() {
			if (!this.pickerValue) return "";
			if (typeof this.pickerValue === "number") return this.pickerValue;
			return (
				(this.pickerValue[1] - this.pickerValue[0]) / (1000 * 60 * 60 * 24)
			);
		},

		timeType() {
			return {
				三个月: 90,
				六个月: 180,
				九个月: 270,
				一年: 365,
			}[this.form.radio];
		},

		returnTimeType() {
			return {
				90: "三个月",
				180: "六个月",
				270: "九个月",
				365: "一年",
			};
		},
	},

	async mounted() {
		const uid = this.$route.query.good_uuid;
		if (!!uid) {
			const { data } = await this.$store.dispatch("goods/getPhysicalDetail", {
				id: uid,
				token: this.$store.state.token,
			});
			const good_download = JSON.parse(data.good_download);
			this.radioContent = !!good_download.download_url ? "1" : "2";

			this.form = {
				radioArea:
					data.ipEmpower.empowerRegion === "全球" ? "全球" : "部分区域",
				checkbox:
					data.ipEmpower.empowerRegion === "全球"
						? []
						: data.ipEmpower.empowerRegion.split("|"),
				radio: !!this.returnTimeType[data.ipEmpower.empowerTime]
					? this.returnTimeType[data.ipEmpower.empowerTime]
					: 1,
				SApproved: data.ipEmpower.empowerSecondCreation,
				limitsOfAuthority: data.ipEmpower.empowerAuthority,
				agent: data.ipEmpower.empowerAgent,
				textarea: data.ipEmpower.empowerInfo,
				title: data.good_title,
				price: data.good_price,
				goodPic: data.good_pic.map((item) => {
					return item.replaceAll(this.baseUrl, "");
				}),
				label: data.label[0].label_name,
				desc: good_download.good_count,
				url: good_download.good_baidu_url,
				pwd: good_download.good_baidu_code,
				fileImg: good_download.download_url,
			};
			this.pickerValue = this.form.radio == 1 ? data.ipEmpower.empowerTime : "";
			this.fileList = data.good_pic.map((item) => {
				return {
					url: item,
				};
			});
		}
	},

	methods: {
		async handleSubmit() {
			try {
				this.radioContent == 2 &&
					(!this.form.url || !this.form.pwd) &&
					this.$message.error("请输入百度网盘地址和提取码");
				this.radioContent == 1 &&
					!this.form.fileImg &&
					this.$message.error("请上传压缩包");
				const data = {
					good_type: this.type,
					empower: {
						empowerTime: this.form.radio === 1 ? this.countDay : this.timeType,
						empowerRegion:
							this.form.radioArea === "全球"
								? this.form.radioArea
								: this.form.checkbox.join("|"),
						empowerAuthority: this.form.limitsOfAuthority,
						empowerAgent: this.form.agent,
						empowerSecondCreation: this.form.SApproved,
						empowerInfo: this.form.textarea,
					},
					good_title: this.form.title,
					good_count: this.form.desc,
					good_price: this.form.price,
					good_inventory: 999,
					good_pic: this.form.goodPic.join("|"),
					good_baidu_url: this.form.url,
					good_baidu_code: this.form.pwd,
					download_url: this.form.fileImg,
					label: this.form.label,
				};
				console.log(data);

				const checkRes = this.handleCheckoutFormData(data);
				if (checkRes.code !== 200) return this.$message.error(checkRes.message);

				if (!this.radio) return this.$message.error("点击同意协议");
				const uid = this.$route.query.good_uuid;
				const res = !!uid
					? await this.$store.dispatch("goods/uploadGoods", {
							...data,
							good_uuid: uid,
					  })
					: await this.$store.dispatch("goods/postAuthorization", data);
				res.code === 200 &&
					this.$message.success("提交成功") &&
					location.reload();
				res.code !== 200 && this.$message.error(res.message);
			} catch (error) {
				console.log(error);
			}
		},

		handleCheckoutFormData(data) {
			let req = {
				message: "",
				code: 200,
			};
			let dataType = {
				good_type: "类型",
				good_title: "授权信息标题",
				good_count: "IP介绍",
				good_price: "授权信息价格",
				good_pic: "上传例图",
				good_baidu_url: "百度网盘地址",
				good_baidu_code: "提取码",
				download_url: "压缩包",
				label: "授权标签",
			};
			if (this.radioContent == 1) {
				delete dataType.good_baidu_url;
				delete dataType.good_baidu_code;
			} else if (this.radioContent == 2) {
				delete dataType.download_url;
			}
			const empowerType = {
				empowerTime: "授权时间",
				empowerRegion: "授权区域",
				empowerAuthority: "授权类型",
				empowerAgent: "授权代理",
				empowerSecondCreation: "授权二次创作",
				empowerInfo: "授权明细",
			};
			try {
				for (let key in data) {
					if (!data[key] && !!dataType[key]) {
						req.message = `请填写${dataType[key]}`;
						req.code = 400;
					}
				}

				for (let key in data.empower) {
					if (!data.empower[key] && !!empowerType[key]) {
						req.code = 400;
						req.message = `请填写${empowerType[key]}`;
					}
				}
				if (data.empower.empowerTime == "自定义") {
					if (!data.empower.empowerTime) {
						req.code = 400;
						req.message = `请填写授权时间`;
					}
				}
				return req;
			} catch (e) {
				console.log(e, "校验ip授权出错了~");
			}
		},

		// 图片上传成功
		handleAvatarSuccess(file) {
			setTimeout(() => {
				this.form.goodPic.push(file.msg);

				this.isLoading(false);
			}, 3000);
		},

		// 图片移除
		handleRemove(file) {
			const removeUrl = file.url.replaceAll(this.baseUrl, "");
			this.form.goodPic.splice(this.form.goodPic.indexOf(removeUrl), 1);
		},

		// 图片上传前做限制
		beforeAvatarUpload(file) {
			this.isLoading(true);
			const fileName = file.name;
			const fileType = fileName.substring(fileName.lastIndexOf("."));
			const type = [".jpg", ".png", ".jpeg", ".gif"];
			const limitSize = 3 * 1024 * 1024;
			if (type.indexOf(fileType) === -1 || limitSize < file.size) {
				this.isLoading(false);
				this.$message.error("上传图片格式不正确");
				return false;
			}
		},
		handleExceed() {
			this.$message.error(`最多上传10个文件`);
		},

		handleGoodsImagesUploadSuccessDoc(response, file, fileList) {
			this.zipFile = file;
			this.isLoading(false);
			if (response.code == 200) {
				this.form.fileImg = response.msg;
				this.$message.success("上传压缩包成功!");
			} else {
				this.$message.error(file.name + "上传压缩包失败!");
			}
		},
		handlebeforeUploadDoc(file) {
			this.isLoading(true);
			let zipType = [
				"application/x-zip-compressed",
				"application/octet-stream",
				"application/zip",
			];
			// 这里做可以做文件校验操作
			const isFile = zipType.includes(file.type);
			const sizeLimit = file.size / 1024 / 1024;
			if (sizeLimit >= 50) {
				this.$message.error("压缩包大小不能超过50MB，请将压缩包压缩至50MB以下");
				this.isLoading(false);
				return false;
			}
			if (!isFile || sizeLimit >= 50) {
				this.$message.error("只能上传ZIP压缩包 格式!");
				this.isLoading(false);
				return false;
			}
		},
		handleUploadExceedDoc() {
			this.$message.error(`最多上传${this.limitDoc}个文件`);
			this.isLoading(false);
		},
		handleRemoveDoc(file, fileList) {
			if (file.status == "success") {
				this.form.fileImg = "";
				console.log("我被删除" + this.form.fileImg);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.IPlicensing {
	.special-text {
		color: #5651ff;
	}
	.form {
		.time {
			position: relative;
			.diy-input {
				position: relative;

				.day {
					height: 34px;
					padding-right: 12px;
					border: none;
					border: 1px solid #acacac;
					border-radius: 8px;
				}
				.content {
					position: absolute;
					right: 10px;
					top: 0;
					color: #acacac;
				}
			}

			.el-date-editor {
				position: absolute;
				opacity: 0;
				z-index: 99;
			}
		}

		.area {
			display: flex;
			align-items: center;
			min-height: 45px;
			flex-wrap: wrap;
			.area-checkbox {
				margin-left: 20px;
			}
		}
		.textarea {
			display: flex;
			align-items: center;
			.el-textarea {
				width: 70% !important;
				margin-right: 3%;
			}
		}
		.info {
			display: flex;
			.price {
				margin-left: 15px;
			}
		}
		.url-pwd {
			.pwd {
				margin-top: 10px;
			}
		}
		.notContent {
			display: flex;
			flex-direction: column;
			color: #acacac;
			.avatar-uploader-icon {
				font-size: 38px;
			}
		}
		.active {
			color: #5651ff;
		}
	}
}
::v-deep .el-icon-date:before {
	content: "元";
}
::v-deep .upload-iplicensing {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	.el-upload--picture-card {
		width: 200px;
		height: 230px;
		line-height: 1.5;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		.upload-text-ip {
			display: flex;
			flex-direction: column;
			color: #8c939d;
		}
	}
	.el-upload-list__item {
		width: 200px;
		height: 230px;
	}
}
::v-deep .el-upload-dragger {
	width: 200px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}
::v-deep .el-input__inner:focus {
	border-color: #5651ff;
}
::v-deep .el-textarea__inner:focus {
	border-color: #5651ff;
}
// ::v-deep .is-active {
// 	border-color: #5651ff !important;
// }
</style>
