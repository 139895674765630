import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

import AddGoods from '@/views/Home/AddGoods';
import Selling from '@/views/Home/Selling';
import Auditing from '@/views/Home/Auditing';
import OrderList from '@/views/Home/OrderList';
import IncomeCenter from '@/views/Home/IncomeCenter';
import Appeal from '@/views/Home/Appeal';
import Apply from '@/views/Home/Apply';
import EnterpriseApply from '@/views/Home/EnterpriseApply';
import Applying from '@/views/Home/Applying';
import goBack from '@/views/Home/WhiteList/GoBack';
import GoodDetail from '@/views/Home/GoodDetail';
import Message from '@/views/Home/Message';
import NotFound from '@/views/Home/NotFound';
import Comment from '@/views/Home/Comment';
import DownList from '@/views/Home/DownList';
import GoodPush from '@/views/Home/Agreement/Goodpush';
import Resign from '@/views/Home/Agreement/Resign'

// 通贩

const routes = [
  {
    path: '/',
    component: () => import('@/views/index.vue'),
    redirect: '/adding',
    children: [
      {
        path: '/adding',
        name: '添加商品',
        component: AddGoods
      }, {
        path: 'selling',
        name: '出售中',
        component: Selling
      }, {
        path: 'auditing',
        name: '审核中',
        component: Auditing
      }, {
        path: 'orderList',
        name: '订单列表',
        component: OrderList
      }, {
        path: 'incomeCenter',
        name: '收益中心',
        component: IncomeCenter
      }, {
        path: 'appeal',
        name: '商品申诉',
        component: Appeal
      }, {
        path: 'detail',
        name: '商品详情',
        component: GoodDetail
      }, {
        path: 'message',
        name:  '消息中心',
        component: Message
      }, {
        path: 'comment',
        name:  '用户评论',
        component: Comment
      }, {
        path: 'downList',
        name: '下架商品列表',
        component: DownList
      }, {
        path:"salesPro",
        component:()=>import("@/views/Tong/SalesPro/index.vue")//设图
      },
      {
        path:"editoc/:id",
        name:"editoc",
        component:()=>import("@/views/Tong/editInfo/editoc.vue")//设图修改
      },
      {
        path:"saleVirtually",
        component:()=>import("@/views/Tong/SaleVirtually/index.vue")//虚拟形象
      },
      {
        path:"saleMaterial",
        component:()=>import("@/views/Tong/SaleMaterial/index.vue")//素材
      },
      {
        path:"editMater/:id",
        name:"editMater",
        component:()=>import("@/views/Tong/editInfo/editMater.vue")//素材修改
      },
      {
        path:"saleMaterialList",
        component:()=>import("@/views/Tong/SaleMaterial/List.vue")//素材列表
      },
      {
        path: "saleMobileCapture",
        component:()=>import("@/views/Tong/SaleMobileCapture/index.vue")//动捕设备
      },
      {
        path:"saleList",
        component:()=>import("@/views/Tong/SaleList/index.vue")//设图列表
      },
      {
        path:"account",
        component:()=>import("@/views/Tong/Account/index.vue")//账户
      },
      {
        path:"order",
        component:()=>import("@/views/Tong/Order/index.vue")//账户
      },
      {
        path: 'sheetSiteSet',
        component:()=>import("@/views/Tong/sheetSite/sheetSiteSet.vue")// 稿位设置
      },
      {
        path: 'sheetSiteList',
        component:()=>import("@/views/Tong/sheetSite/sheetSiteList.vue")// 稿位列表
      },
      {
        path: 'editSheetSite/:id',
        name: 'editSheetSite',
        component: ()=> import('@/views/Tong/editInfo/editSheetSite.vue') // 稿位修改
      },
      {
        path: 'agreePublic',
        component:()=>import("@/views/Tong/agreement/agreePublic.vue") // 稿位发布协议
      }, {
        path:"/saleList",
        component:()=>import("@/views/Tong/SaleList/index.vue")//设图列表
      },
      {
        path:'addCrowdFunding',
        name:"添加众筹",
        component:()=>import("@/views/Tong/CrowdFunding/crowdFundingAdd.vue")
      },
      {
        path:'editCrowdFunding',
        name:"编辑众筹",
        component:()=>import("@/views/Tong/CrowdFunding/crowdFundingAdd.vue")
      },
      {
        path:'crowdMyList',
        name:"众筹列表",
        component:()=>import("@/views/Tong/CrowdFunding/crowdMyList.vue")
      },
      {
        path:'crowdMyOrder',
        name:"众筹订单",
        component:()=>import("@/views/Tong/CrowdFunding/crowdMyOrder.vue")
      },
      {
        path:'crowdMyAfterSale',
        name:"众筹售后列表",
        component:()=>import("@/views/Tong/CrowdFunding/crowdMyAfterSale.vue")
      },
    ]
  },
  {
    path: '/apply',
    name: '个人申请',
    component: Apply
  }, {
    path: '/enterpriseApply',
    name: '企业申请',
    component: EnterpriseApply
  }, {
    path: '/isApplying',
    name: '正在申请中',
    component: Applying
  }, {
    path: '/goBack',
    name: '返回',
    component: goBack
  }, {
    path: '/GoodPush',
    name: '橱窗申请发布协议',
    component: GoodPush
  }, {
    path: '/Resign',
    name: '注册协议',
    component: Resign
  } ,{
    path:"/bidding",
    component:()=>import("@/views/Tong/agreement/agreeBidding.vue")//竞价协议
  }, {
    path:"/agreepdd",
    component:()=>import("@/views/Tong/agreement/agreePdd.vue")//pdd协议
  }, {
    path:"/agreeSellMater",
    component:()=>import("@/views/Tong/agreement/sellMater.vue")//素材出售协议
  }, {
    path:"/agreeoc",
    component:()=>import("@/views/Tong/agreement/agreeoc.vue")//美术作品交易服务协议
  }, {
    path:"/agreeSell",
    component:()=>import("@/views/Tong/agreement/aggreeSell.vue")//美术作品出售规则协议
  }, {
    path:"/agreeWithdrawal",
    component:()=>import("@/views/Tong/agreement/agreeWithdrawal.vue")//提现服务协议
  }, {
    path:"/agreeRecharge",
    component:()=>import("@/views/Tong/agreement/agreeRecharge.vue")//充值协议
  }, {
    path: '/login',
    component: () => import( '@/views/Login')
  }, {
    path: '*',
    component: NotFound
  },
]

const Whitelist = [
    // '/apply',
    '/enterpriseApply',
    '/isApplying',
    '/goBack',
    '/GoodPush',
    '/Resign',
    '/bidding',
    '/agreepdd',
    '/agreeSell',
    '/agreeWithdrawal',
    '/agreeRecharge',
    '/login'
]

const router = new VueRouter({
  routes
})

router.beforeEach(async(to, from, next) => {

  await store.dispatch('getBaseUrl')
  try {
    if (to.query?.token) {
      await store.dispatch('getUserInfo', to.query?.token)
      next(to.path)
    }
    // 白名单判断
    const is_Whitelist = Whitelist.some(item => item === to.path)

    const isToken = JSON.parse(localStorage.getItem('token'))
    if(to.path === '/login' && isToken) return next('/')

    if (!is_Whitelist) {
      // 检测是否实名申请 2:实名认证已申请
      // const isMyApply = JSON.parse(localStorage.getItem('myrenzheng'))
      // if (isMyApply !== 2) return next('/goBack')
      /**
       * isApply状态是判断用户是否申请使用本项目的资格
       * 0： 没有申请，跳申请页面
       * 1： 正在申请中
       * 2： 申请过了
       */
      // 橱窗状态
      const isApplyMsg  = JSON.parse(localStorage.getItem('isApplyMsg'))
      if (isApplyMsg === 0 && to.path !== '/apply') return next('/apply')
      // 正在申请中
      if (isApplyMsg === 1 && to.path !== '/isApplying') return next('/isApplying')
    }
    next()
  } catch (error) {
    console.log(error, '路由守卫出问题咯~');
  }
})

// 检测路由是否出问题
router.onError((error) => {
  const jsPattern = /Loading chunk (\S)+ failed/g
  const cssPattern = /Loading CSS chunk (\S)+ failed/g
  console.log(error, '路由出问题了~');
  const isChunkLoadFailed = error.message.match(jsPattern || cssPattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    localStorage.setItem('targetPath', targetPath)
    window.location.reload()
  }
})

export default router
