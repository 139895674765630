<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'margin:0;')
  },
  async mounted() {
    await this.getUserInfo()
    await this.$store.dispatch('getBaseUrl')
  },

  methods: {
    async getUserInfo() {
      const token = JSON.parse(localStorage.getItem('token'))
      if (token) await this.$store.dispatch('getUserInfo', token)
    }
  }
}

</script>
<style lang="scss">
#app {
  margin: 0;
  background-color: #fbfaff;
  min-height: 1000px;
}
/* 背景版样式 */
.banner {
  max-width: 1300px;
  margin: 0 auto;
  //background-color: #fbfaff;
  min-height: 760px;
  border-radius: 10px;
  padding: 50px 20px;
}
.hand{
  cursor: pointer;
}

.w-base{
  width: 70%;
}
.color-base{
  color: #6357ff;
}
.main-btn{
  width: 90px;
  height: 40px;
  border-radius: 12px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background-color: #6357FF;
}
.bottom-line{
  border-bottom: 1px solid #F1F1FA;
  margin-bottom: 15px;
}
 .my-popconfirm{
  .el-popconfirm__action{
    .el-button--text {
      color: #6357FF;
    }
    .el-button--primary {
      background-color: #6357FF;
      color: #fff;
      border: none;
    }
  }
}
</style>
