<template>
	<div class="auditing container-global-color container-global-table">
		<top-button :button_list="radios" @ok="handleSelect" :active="radio" />

		<el-table
			:data="tableData"
			:stripe="true"
			border
			:header-cell-style="{ background: '#fafafa' }"
			style="width: 100%">
			<el-table-column
				v-for="tabelHeader in tableHeaders"
				:key="tabelHeader.id"
				:prop="tabelHeader.id"
				:label="tabelHeader.name"
				align="center">
				<template slot-scope="scope">
					<span v-if="tabelHeader.name === '审核状态'">
						{{ auditStatusType[scope.row.state] }}</span
					>
					<span
						v-else-if="tabelHeader.name === '查看详情'"
						class="table-actions title"
						@click="
							scope.row.good_type != 3 &&
								$router.push({
									path: 'detail',
									query: {
										good_uuid: scope.row.good_uuid,
										type: scope.row.good_type,
									},
								})
						"
						>{{ scope.row.good_title }}</span
					>

					<span v-else> {{ scope.row[[tabelHeader.id]] }}</span>
				</template>
			</el-table-column>

			<el-table-column prop="action" label="操作" align="center" width="180px">
				<template slot-scope="scope">
					<span v-if="!scope.row.state">
						<el-popconfirm
							popper-class="my-popconfirm"
							title="确定要将此商品撤回审核吗？"
							@confirm="handleDelete(scope.row)">
							<span class="table-actions" slot="reference">撤回审核</span>
						</el-popconfirm>
					</span>
					<span v-else>
						<span
							class="table-actions"
							@click="
								$router.push({
									path: '/',
									query: {
										good_uuid: scope.row.good_uuid,
										type: scope.row.good_type,
									},
								})
							"
							>修改信息</span
						>
						<!-- <span class="middle">|</span>
            <a href="#">查看原因</a> -->
					</span>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination
			class="pagination"
			:page-size="pageSize.keySize"
			layout="prev, pager, next, jumper"
			:current-page.sync="currentPage"
			@current-change="handleCurrentChange"
			:total="total">
		</el-pagination>
	</div>
</template>

<script>
import { TopButton } from "@/views/Home/components";

export default {
	components: { TopButton },
	data() {
		const auditStatusType = {
			0: "审核中",
			2: "未通过",
			1: "审核通过",
		};

		return {
			currentPage: 1,

			auditStatusType,
			radios: [
				{ label: 0, name: "全部" },
				{ label: 1, name: "虚拟" },
				{ label: 2, name: "实体" },
				{ label: 3, name: "IP授权" },
			],
			radio: 1,

			tableHeaders: [
				{ id: "good_uuid", name: "出售单号" },
				{ id: "good_price", name: "出售金额" },
				{ id: "creat_time", name: "上架时间" },
				{ id: "lookForDetail", name: "查看详情" },
				{ id: "good_type_key", name: "出售类型" },
				{ id: "state", name: "审核状态" },
			],
			tableData: [],
			pageSize: {
				page: 1,
				size: 10,
			},
			total: 0,

			goodType: "",
		};
	},

	computed: {},

	watch: {},

	methods: {
		async getList() {
			try {
				const { data, total } = await this.$store.dispatch(
					"goods/getProductList",
					{
						order: "",
						sort: "desc",
						...this.pageSize,
						type: 0,
						good_type: this.goodType,
					}
				);
				console.log(data);
				this.total = total;
				this.tableData = data;
			} catch (e) {
				console.log(e, "获取商品列表出错了~~");
			}
		},

		// 筛选虚拟、实体
		async handleSelect(val) {
			try {
				this.radio = val;
				console.log(val);

				this.pageSize.page = 1;
				this.currentPage = 1;
				this.goodType = val;
				await this.getList();
			} catch (e) {
				//TODO handle the exception
				console.log(e, "筛选商品列表出错了~~");
			}
		},

		// 分页器
		async handleCurrentChange(val) {
			this.pageSize.page = val;
			this.currentPage = val;
			await this.getList();
		},

		// 撤回审核
		async handleDelete(row) {
			console.log(row);
			try {
				const res = await this.$store.dispatch("goods/withdrawAudit", {
					id: row.id,
				});
				if (res.code === 200) {
					this.$message.success(res.msg);
				} else {
					this.$message.error(res.msg);
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e, "撤回审核失败啊");
			}
		},
	},

	created() {},

	async mounted() {
		await this.getList();
	},
};
</script>
<style scoped lang="scss">
.auditing {
	.middle {
		margin: 0 10px;
	}

	::v-deep .pagination {
		text-align: right;
		padding-top: 20px;
		.el-pager li.active {
			color: #6357ff;
			cursor: default;
		}
	}
	.title {
		color: blue;
	}
}
</style>
