var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selling container-global-color container-global-table"},[_c('top-button',{attrs:{"button_list":_vm.radios,"active":_vm.radio},on:{"ok":_vm.handleSelect}}),_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":true,"border":"","header-cell-style":{ background: '#fafafa' }}},[_vm._l((_vm.tableHeader),function(header){return _c('el-table-column',{key:header.id,attrs:{"label":header.name,"prop":header.id,"sortable":header.isSort,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(header.name === '查看详情')?_c('span',{staticClass:"actions title",on:{"click":function($event){scope.row.good_type != 3 &&
								_vm.$router.push({
									path: 'detail',
									query: {
										good_uuid: scope.row.good_uuid,
										type: scope.row.good_type,
									},
								})}}},[_vm._v(_vm._s(scope.row.good_title)+" ")]):_c('span',[_vm._v(_vm._s(scope.row[header.id]))])]}}],null,true)})}),_c('el-table-column',{attrs:{"label":"操作","width":"180px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"actions",on:{"click":function($event){return _vm.$router.push({
								path: '/',
								query: {
									good_uuid: scope.row.good_uuid,
									type: scope.row.good_type,
								},
							})}}},[_vm._v("修改信息")]),_c('span',{staticClass:"middle"},[_vm._v("|")]),_c('el-popconfirm',{attrs:{"popper-class":"my-popconfirm","title":"确定要下架此商品吗？"},on:{"confirm":function($event){return _vm.handleDelete(scope.row)}}},[_c('span',{staticClass:"actions",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("下架商品")])])]}}])})],2)],1),_c('el-pagination',{staticClass:"pagination",attrs:{"page-size":_vm.pageSize.keySize,"layout":"prev, pager, next","current-page":_vm.currentPage,"total":_vm.total},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"current-change":_vm.handleCurrentChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }