<template>
	<el-dialog
		title="图片位置排序"
		class="sort-dialog"
		:visible.sync="sortDialogVisible"
		width="50%"
		append-to-body>
		<div class="all-images" v-loading="isLoading">
			<el-image
				class="image"
				v-for="image in submitImages"
				:key="image"
				:src="image"
				@dragstart="dragStart(image)"
				@dragend="dragEnd()"
				@dragover="onDragOver($event)">
				<div slot="error" class="image-slot">
					<i class="el-icon-picture-outline"></i>
					<span>加载失败</span>
				</div>
			</el-image>
		</div>
		<div slot="footer">
			<el-button @click="sortDialogVisible = false">取 消</el-button>
			<el-button type="primary" @click="handleOver">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			sortDialogVisible: false,
			targetSrc: "",
			currentSrc: "",
			submitImages: [],
			baseUrl: this.$store.state.baseUrl,
			isZip: false,
			isLoading: false,
		};
	},
	computed: {},
	watch: {},
	methods: {
		show(images) {
			let submintImages = images.map((item) => {
				if (item.includes("https")) {
					return item;
				} else {
					return this.baseUrl + item;
				}
			});
			this.submitImages = submintImages;
			this.sortDialogVisible = true;
		},

		dragStart(image) {
			this.currentSrc = image;
		},

		onDragOver(event) {
			this.targetSrc = event.target.currentSrc;
		},

		dragEnd() {
			this.isLoading = true;
			if (this.currentSrc !== this.targetSrc) {
				const currentIndex = this.submitImages.indexOf(this.currentSrc);
				const targetIndex = this.submitImages.indexOf(this.targetSrc);
				this.submitImages.splice(currentIndex, 1, this.targetSrc);
				this.submitImages.splice(targetIndex, 1, this.currentSrc);
			}
			this.isLoading = false;
		},

		handleOver() {
			this.$emit("ok", this.submitImages);
			this.sortDialogVisible = false;
		},
	},
	created() {},
	mounted() {},
};
</script>
<style scoped lang="scss">
.sort-dialog {
	.all-images {
		display: flex;
		flex-wrap: wrap;
		.image {
			width: 100px;
			margin: 5px;
			cursor: pointer;
		}
	}
}
::v-deep .el-image__placeholder {
	background: url("https://images.chuoooo.com/newxunhuan.gif") no-repeat 50% 50% !important;
	background-size: 50% !important;
}
::v-deep .el-image__inner {
	object-fit: contain !important;
	cursor: pointer;
}
</style>
