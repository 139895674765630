<template>
<div class='go-back banner'>
  <div>
    <img src="@/assets/img/noToken.png" alt="去认证" class="img">
    <div class="tip">申请橱窗请先注册触圈账号，并通过实名认证~</div>
    <el-button type="danger" round @click="regusit()" plain>实名认证</el-button>
    <div>前往触圈<a href="https://chuoooo.com/login" target="_blank">注册账号</a></div>
  </div>

</div>
</template>

<script>

export default {
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  methods: {
    regusit() {
      // window.open('https://chuoooo.com/login', '_blank')
      window.open('https://chuoooo.com/UserInfo', '_blank')
    }
  },
  created() {

  },
  mounted() {

  },
}
</script>
<style scoped lang="scss">
.go-back {
  text-align: center;
  color: #b5b4cc;
  .img {
    width: 400px;
  }
  .tip {
    margin: 10px  0 20px 0;
    color: #828199;
  }

  a {
    color: #b776f6;
  }
}
::v-deep .el-button {
  background: linear-gradient(to right, #ffaed5 , #f184f0 , #b375f7)!important;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 10px 50px;
  margin-bottom: 10px;
  span {
    color: #fff;
  }
}

</style>