<template>
	<div class="add-goods container-global-color">
		<div class="container-global-title">
			<h1>添加商品</h1>
		</div>

		<div class="container-global-form">
			<el-form ref="form" status-icon label-width="100px" class="form">
				<el-form-item label="商品类型" prop="type">
					<!-- @input="handleInput()" -->
					<el-radio-group v-model="type">
						<el-radio
							:label="item.value"
							name="type"
							v-for="item in checkList"
							:key="item.value"
							>{{ item.label }}</el-radio
						>
					</el-radio-group>
				</el-form-item>

				<component
					:is="componentName[type]"
					:type="type"
					:radio="radio"
					ref="component"></component>

				<el-form-item class="center">
					<el-checkbox v-model="radio">
						点击出售即表示同意<a href="#/GoodPush" style="color: #6357ff"
							>《橱窗申请发布协议》</a
						>且愿意遵守该协议中规定事项</el-checkbox
					>

					<div class="submit">
						<el-button @click="handleSubmit" class="sale-class"
							>立即出售</el-button
						>
					</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import VandPVue from "./components/VandP.vue";
import IPlicensing from "./components/IPlicensing.vue";
export default {
	components: {
		VandPVue,
		IPlicensing,
	},

	data() {
		return {
			checkList: [
				{ label: "虚拟商品", value: 1 },
				{ label: "实体商品", value: 2 },
				{ label: "IP授权", value: 3 },
			],
			type: this.$route.query.type ? Number(this.$route.query.type) : 1,
			radio: false,
		};
	},

	computed: {
		componentName() {
			return {
				1: "VandPVue",
				2: "VandPVue",
				3: "IPlicensing",
			};
		},
	},
	methods: {
		handleSubmit() {
			this.$refs.component.handleSubmit();
		},
	},
};
</script>

<style lang="scss" scoped>
.center {
	padding-top: 20px;
	border-top: 1px solid #666;
	text-align: center;
	.submit {
		margin-top: 10px;
		.sale-class {
			background: linear-gradient(to right, #fecee5, #f8b6f5, #d1acfa);
			border: none;
			color: #fff;
			border-radius: calc(0.5rem * 4);
		}
	}
}
::v-deep .upload-box {
	display: flex;
	justify-content: space-between;
	color: #d5d5d5;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: #6357ff;
	border-color: #6357ff;
}
::v-deep .el-checkbox__inner:hover {
	border-color: #6357ff;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
	color: #6357ff;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
	color: #6357ff;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
	background: #fbfaff;
	border-color: #6357ff;
	&::after {
		width: 6px;
		height: 6px;
		background-color: #6357ff;
	}
}
</style>
